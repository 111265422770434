import type { GetServerSideProps, NextPage } from 'next';
import { z } from 'zod';
import {
  ssrThemeProps,
  getVisitorId,
  getApolloSsrClient,
} from '@stitch-fix/sf-next/server';
import querystring from 'querystring';
import { modularPageWrapperSchema } from '../../components/ModularPageWrapper/schema';
import { getCachedEntryByUrl } from '../../utils/requests/contentstack/cachedRequests';
import { formatError, serverLogger } from '../../utils/loggers/server';
import { ModularPageWrapper } from '../../components/ModularPageWrapper';
import {
  isDynamicAdsLandingPage,
  shouldAllocateSupVisitor,
  shouldRedirectToReactivation,
} from '../../utils/redirects';
import { allocateVisitorSsr } from '../../utils/allocateVisitorSsr/allocateVisitorSsr';
import { SUP_REDIRECT_EXPERIMENT_NAME } from '../../utils/helpers';
import {
  isAllSettledResponseFailure,
  logResponsesFromAllSettledSSR,
} from '../../utils/promises';
import { getDynamicAdsRequest } from '../../features/dynamicAds/utils/getDynamicAdsRequest';
import { getModulesWithHeroOverride } from '../../features/dynamicAds/utils/getModulesWithHeroOverride';

export type WomenPageProps = z.infer<typeof modularPageWrapperSchema>;

export const getServerSideProps: GetServerSideProps<WomenPageProps> = async ({
  locale,
  req,
  query,
}) => {
  const url = req.url || '';
  const stringifiedQuery = querystring.stringify(query);

  if (shouldRedirectToReactivation(url)) {
    return {
      redirect: {
        destination: `/women/reactivation?${stringifiedQuery}`,
        permanent: false,
      },
    };
  }

  const visitorId = getVisitorId(req);
  const apolloSsrClient = getApolloSsrClient({ req });

  try {
    const entryRequest = await getCachedEntryByUrl({
      contentType: 'modular_page',
      url: '/womens-onboarding-vision',
      language: locale,
      schema: modularPageWrapperSchema,
      logger: serverLogger,
    });

    let dynamicAdsRequest: ReturnType<typeof getDynamicAdsRequest> | null =
      null;

    // only call the dynamic ads algos if the page is a dynamic ads landing page
    if (isDynamicAdsLandingPage(url)) {
      dynamicAdsRequest = getDynamicAdsRequest({ req });
    }

    // If a visitor to /women or /auto/d/women has params indicating they're a SUP client
    // we allocate them to the SUP Landing Page experiment and redirect the treatment visitors to a SUP page
    const supExperimentRequest = shouldAllocateSupVisitor(url)
      ? allocateVisitorSsr({
          req,
          visitorId,
          apolloSsrClient,
          configParams: [
            {
              expectedValues: ['control', 'treatment'],
              fallbackValue: 'control',
              name: SUP_REDIRECT_EXPERIMENT_NAME,
            },
          ],
        })
      : null;

    /**
     * this allows us to handle a dynamic number of requests in a non-synchronous way
     */
    const responses = await Promise.allSettled([
      entryRequest,
      dynamicAdsRequest,
      supExperimentRequest,
    ]);

    logResponsesFromAllSettledSSR({
      responses,
      message: 'women data error',
      team: 'first-touch',
    });

    const [entryResponse, dynamicAdsResponse, supExperimentResponse] =
      responses;

    if (isAllSettledResponseFailure(entryResponse)) {
      throw entryResponse.reason;
    }

    const shouldRedirectToSupPage =
      supExperimentResponse.status === 'fulfilled' &&
      supExperimentResponse.value?.data?.visitor.configParams.some(
        p => p.name === SUP_REDIRECT_EXPERIMENT_NAME && p.value === 'treatment',
      );

    if (shouldRedirectToSupPage) {
      return {
        redirect: {
          destination: `/women/your-first-fix?${stringifiedQuery}`,
          permanent: false,
        },
      };
    }
    const dynamicAdsData =
      dynamicAdsResponse.status === 'fulfilled'
        ? dynamicAdsResponse.value?.data
        : undefined;

    return {
      props: {
        seoData: entryResponse.value.data.seoData,
        modules: getModulesWithHeroOverride({
          modules: entryResponse.value.data.modules,
          dynamicAds: dynamicAdsData,
        }),
        ...(await ssrThemeProps({
          req,
          overrideTheme: 'brand-2024-full',
        })),
      },
    };
  } catch (error: unknown) {
    serverLogger({
      level: 'error',
      team: 'first-touch',
      message: 'Required page data is undefined - Women',
      context: {
        error: formatError(error),
        page: '/women',
        severity: 'critical',
      },
    });

    throw new Error('Required data not provided to render Women page');
  }
};

const WomenPage: NextPage<WomenPageProps> = ({ seoData, modules }) => {
  return <ModularPageWrapper seoData={seoData} modules={modules} />;
};

export default WomenPage;
